/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// API

import ApiService from './api';
ApiService.init(process.env.VUE_APP_API_URL);

if (localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace')) !== null) {
  ApiService.setHeader(localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace')));
}

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'

import 'flatpickr/dist/flatpickr.css';

import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css'

// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// VeeValidate
import VeeValidate from 'vee-validate';
import de from 'vee-validate/dist/locale/de';
VeeValidate.Validator.localize('de', de);

VeeValidate.Validator.extend("decimal", {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'The {_field_} field must contain only decimal values'
})

VeeValidate.Validator.extend("requiredDomain", {
  validate: (value) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
        message: 'The {_field_} field is required'
      };
    }
    // Regex für eine gültige Domain ohne Protokoll und Pfade
    const regex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return {
      valid: regex.test(value),
      message: 'The {_field_} field must be a valid domain'
    };
  },
  message: 'The {_field_} field must be a valid domain'
});

VeeValidate.Validator.extend('uuidv4', {
  validate: (value) => {
    const regex = /^[0-9a-fA-F]{32}$/;
    return regex.test(value);
  },
  message: 'The {_field_} field must be a valid UUID v4 without dashes'
});

Vue.use(VeeValidate, {
  locale: 'de',
});

// VeeValidate
import Vuetable from 'vee-validate';
Vue.use(Vuetable)


import VueMoment from 'vue-moment';
Vue.use(VueMoment)


// Echo & Pusher

import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  authEndpoint: process.env.VUE_APP_PUBLIC_URL + 'broadcasting/auth',
  broadcaster: 'pusher',
  key: 'ffa338f0cdc9d1defefe',
  cluster: 'eu',
  encrypted: true,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace'))}`
    }
  }
});

//Currency Input
import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Vue2Editor
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import { Quill } from "vue2-editor";

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);



// ACE Editor
import VueAce from '@aminoeditor/vue-ace';
Vue.use(VueAce);

// Feather font icon
require('./assets/css/iconfont.css')

import TreeView from "vue-json-tree-view"
Vue.use(TreeView)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

// i18next
import VueI18next from '@panter/vue-i18next';
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';

Vue.use(VueI18next);

require('./plugins/filter');


const i18nextOptions = {
  lng: "de",
  backend: {
    loadPath: process.env.VUE_APP_API_URL + 'translations/translation?lang={{lng}}',
    addPath: process.env.VUE_APP_API_URL + 'translations/translation',
    allowMultiLoading: false,
    parse: function (data) { return JSON.parse(data); },
  }
}

i18next
  .use(XHR)
  .init(i18nextOptions)


const i18n = new VueI18next(i18next);

import PermissionPlugin from './plugins/permission';

Vue.use(PermissionPlugin);

Vue.prototype.$globalUrl = process.env.VUE_APP_PUBLIC_URL;

new Vue({
    router,
    store,
  i18n,
    render: h => h(App)
}).$mount('#app')
