import ApiService from "../../api";

export default {
    state: {
        notifications: []
    },

    getters: {
        notifications: (state) => {
            return state.notifications;
        },
    },

    actions: {
        updateNotifications({ commit }) {
            ApiService.get('/notifications').then((response) => {
                commit('SET_NOTIFICATIONS', response.data.result);
            });
        },
        markNotification({ commit }, id) {
            ApiService.delete('/notifications/' + id).then((response) => {
                commit('REMOVE_NOTIFICATION', id);
            });
        },
        markAllNotifications({ commit }) {
            ApiService.delete('/notifications').then((response) => {
                commit('SET_NOTIFICATIONS', []);
            });
        },
    },

    mutations: {
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        },
        REMOVE_NOTIFICATION(state, id) {
            state.notifications = state.notifications.filter(item => item.id !== id);
        },
    }
}
