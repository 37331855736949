import store from '../../store/store'

const PermissionPlugin = {

    install(Vue, options) {
        Vue.prototype.$p = function(name) {
            const userDate = store.getters['user'];

            if(userDate.roles.length === 0) {
                return false;
            }

            if(userDate.roles[0].permissions.length === 0) {
                return false;
            }

            const permissions = userDate.roles[0].permissions;

            if (permissions.filter(item => item.name === name).length === 0) {
                return false;
            }

            return true;
        }
    }
};

export default PermissionPlugin;