import Vue from 'vue';

Vue.filter('currency', function (value) {
    let number = value.toString();

    return parseFloat(number.replace(',', '.')).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
        useGrouping: true
    });
});

Vue.filter('tax', function (value) {
        return value + '%';
});