export default {
    state: {
        user: {},
        workspace: {}
    },

    getters: {
        loggedIn: (state) => {
            return Object.keys(state.user).length > 0;
        },
        user: (state) => {
            return state.user
        },
        userId: (state) => {
            if(!state.user.detail) return null;
            return state.user.id
        },
        userFullName: (state) => {
            if(!state.user.detail) return null;
            return state.user.detail.first_name+" "+state.user.detail.last_name;
        },
        workspace: (state) => {
            if(!state.workspace) return null;
            return state.workspace;
        },
        userHasPermission: (state) => (name) => {
            if(!state.user || state.user.length === 0) {
                return false;
            }

            if(state.permissions && state.user.permissions > 0) {
                if(state.user.permissions.find(item => item.name === name)) {
                    return true;
                }
            }

            if(state.user.roles && state.user.roles.length > 0) {
                const roles = state.user.roles;
                const rolesCount = state.user.roles.length;
                for (let i = 0; i < rolesCount; i++) {
                    if(roles[i].permissions.find(item => item.name === name)) {
                        return true;
                    }
                }
            }

            return false;
        }
    },

    actions: {
    },

    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_WORKSPACE(state, workspace) {
            state.workspace = workspace;
        },
    }
}
