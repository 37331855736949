export default {
  state: {
    file: []
  },

  getters: {
    getFileContent: (state) => {
      return state.file;
    }
  },

  actions: {
    updateFileContent({ commit }, file)  {
      commit('setFileContent', file);
    }
  },

  mutations: {
    setFileContent(state, file) {
      state.file = file;
    }
  }
}
