import axios from 'axios'
import Vue from 'vue';
import ERROR_MESSAGES from './error-messages';

const errorComposer = (error) => {
    return () => {
        if (error.response && error.response.data && error.response.data.error_code) {
            const errorCode = error.response.data.error_code;
            const message = ERROR_MESSAGES[errorCode] || errorCode;

            Vue.prototype.$vs.notify({
                title: 'Fehler',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                time: 6000, // 6 seconds
                text: message
            })
        } else if (error.handleUnkown) {
            Vue.prototype.$vs.notify({
                title: 'Fehler',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                time: 6000, // 6 seconds
                text: error.unkownMessage || 'Ein unbekannter Fehler ist aufgetreten'
            })
        }
    }
}


const successNotifier = (message, title = 'Erfolgreich') => {
    Vue.prototype.$vs.notify({
        title: title,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 3000, // 3 seconds
        text: message
    })
}


const errorNotifier = (message, title = 'Fehler') => {
    Vue.prototype.$vs.notify({
        title: title,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
        time: 3000, // 3 seconds
        text: message
    })
}


const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
        axios.defaults.withCredentials = true;

        axios.interceptors.response.use(
            function (response) {
                response.successNotifier = successNotifier;
                return Promise.resolve(response);
            },
            function (error) {
                error.handleGlobally = errorComposer(error);
                error.errorNotifier = errorNotifier;
                return Promise.reject(error);
            });
    },

    setHeader(credentials) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + credentials
        if ($cookies.get("mysql_bank_password")){
            axios.defaults.headers.MysqlBankPassword = $cookies.get("mysql_bank_password")
        }
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource, options) {
        return axios.get(resource, options)
    },

    post(resource, data, options) {
        return axios.post(resource, data, options)
    },

    put(resource, data, options) {
        return axios.put(resource, data, options)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    customRequest(data) {
        return axios(data)
    }
}

export default ApiService