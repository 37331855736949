<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="app">
        <router-view v-if="userLoaded"></router-view>
    </div>
</template>

<script>
    import themeConfig from '@/../themeConfig.js'
    import ApiService from "./api";
    import Vue from 'vue';
    import VueClipboard from 'vue-clipboard2'
    VueClipboard.config.autoSetContainer = true // add this line
    Vue.use(VueClipboard)

    export default {
        data() {
            return {
                userLoaded: false
            }
        },
        watch: {
            '$store.state.theme'(val) {
                this.toggleClassInBody(val)
            }
        },
        methods: {
            toggleClassInBody(className) {
                if (className == 'dark') {
                    if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
                    document.body.classList.add('theme-dark')
                }
                else if (className == 'semi-dark') {
                    if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
                    document.body.classList.add('theme-semi-dark')
                }
                else {
                    if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
                    if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
                }
            },
            handleWindowResize() {
                this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
            },
            handleScroll() {
                this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
            }
        },
        mounted() {
            this.toggleClassInBody(themeConfig.theme)
            this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
        },
        async created() {
            let me = this;

            Vue.prototype.$vs = this.$vs;

            if (localStorage.getItem('current_workspace') && localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace')) !== null) {

                ApiService.get('users/' + localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace'))).then((response) => {

                    if (response.status !== 200) {
                        localStorage.removeItem('apiKey_'+localStorage.getItem('current_workspace'));
                        return me.$router.push('/login');
                    }

                    me.$store.commit('SET_USER', response.data.result);

                    ApiService.get('workspaces/' + response.data.result.workspace_id).then((response) => {
                      me.$store.commit('SET_WORKSPACE', response.data.result);
                    }).catch(response => {
                      localStorage.removeItem('apiKey_'+localStorage.getItem('current_workspace'));
                      me.$router.push('/login');
                      this.userLoaded = true;
                    })

                    me.$store.dispatch('fetchModelDefinitions');

                    this.userLoaded = true;
                    Echo.join('app');

                }).catch(response => {
                    localStorage.removeItem('apiKey_'+localStorage.getItem('current_workspace'));
                    me.$router.push('/login');
                    this.userLoaded = true;
                })
            } else {
                me.$router.push('/login');
                this.userLoaded = true;
            }

            window.addEventListener('resize', this.handleWindowResize)
            window.addEventListener('scroll', this.handleScroll)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize)
            window.removeEventListener('scroll', this.handleScroll)
        },
    }

</script>
