import ApiService from "../../api";

export default {
    state: {
        modelDefinitions: []
    },

    getters: {
        modelDefinitions: (state) => {
            return state.modelDefinitions;
        },
        getModelDefinitionByTableName: (state) => (tableName) => {
            return state.modelDefinitions.find(definition => {
                return definition.tableName === tableName;
            });
        },
        getModelDefinitionByClass: (state) => (className) => {
            return state.modelDefinitions.find(definition => {
                return definition.model === className;
            });
        }
    },

    actions: {
        fetchModelDefinitions({commit}) {
            ApiService.get('/model-definitions').then((response) => {
                commit('SET_MODEL_DEFINITIONS', response.data.result);
            });
        }
    },

    mutations: {
        SET_MODEL_DEFINITIONS(state, modelDefinitions) {
            state.modelDefinitions = modelDefinitions;
        },
    }
}
