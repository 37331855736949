/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import qs from 'qs';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    parseQuery(query) {
        return qs.parse(query);
    },
    stringifyQuery(query) {
        let result = qs.stringify(query, {encode: false});
        return result ? ('?' + result) : '';
    },
    routes: [

        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            meta: {
                authRequired: true
            },
            children: [
                // =============================================================================
                // Home Routes
                // =============================================================================
                {
                    path: '/', name: 'home', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/calendar',
                    name: 'calendar',
                    component: () => import('./views/dashboard/Calendar/Calendar.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'calendar'
                    },
                },
                {
                    path: '/scheduler',
                    name: 'scheduler',
                    component: () => import('./views/dashboard/Scheduler/Scheduler.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Mein Arbeitsplatz', url: '/'},
                            {title: 'Resourcen Planer', url: '/scheduler', active: true}
                        ],
                        pageTitle: 'Resourcen Planer',
                        authRequired: true,
                        parent: 'scheduler'
                    },
                },
                {
                    path: '/commissions', name: 'commissions', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/reimbursement', name: 'reimbursement', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/add-protokoll', name: 'add-protokoll', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // Working-Hours Routes
                // =============================================================================
                {
                    path: '/holidays', name: 'holidays', component: () => import('./views/working-time-report/AbsenceIndex.vue'),
                    meta: {
                        authRequired: true,
                        pageTitle: 'Urlaubsanträge',
                    },
                },
                {
                    path: '/working-time-report',
                    name: 'WorkingTimeReport',
                    component: () => import('./views/working-time-report/WorkingTimeReport.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/working-time-report/:userId/',
                    name: 'WorkingTimeReportUser',
                    component: () => import('./views/working-time-report/WorkingTimeReport.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/working-time-report/:userId/:year/:month',
                    name: 'WorkingTimeReportUserYearMonth',
                    component: () => import('./views/working-time-report/WorkingTimeReport.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // Organization Routes
                // =============================================================================
                {
                    path: '/customers',
                    name: 'customers',
                    component: () => import('./views/organization/clients/Clients.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Kunden', url: '/customers', active: true}
                        ],
                        pageTitle: 'Kunden',
                        parent: 'customers'
                    },
                },
                {
                    path: '/customers/add',
                    name: 'customers-add',
                    component: () => import('./views/organization/clients/AddClient.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Kunden', url: '/customers', active: true},
                            {title: 'Hinzufügen', url: '/customers/add', active: true}
                        ],
                        pageTitle: 'Kunden hinzufügen',
                        parent: 'customers'
                    },
                },
                {
                    path: '/customers/:id',
                    name: 'customers-detail',
                    component: () => import('./views/organization/clients/Client.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Kunden', url: '/customers', active: true},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Kunde bearbeiten',
                        parent: 'customers'
                    }
                },
                {
                    path: '/contacts',
                    name: 'contacts',
                    component: () => import('./views/organization/contacts/Contacts.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Kontakte', active: true}
                        ],
                        pageTitle: 'Kontakte',
                        parent: 'contacts'
                    },
                },
                {
                    path: '/contacts/add',
                    name: 'contacts-add',
                    component: () => import('./views/organization/contacts/AddContact.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Kontakte', url: '/contacts', active: true},
                            {title: 'Hinzufügen', active: true}
                        ],
                        pageTitle: 'Kontakt hinzufügen',
                        parent: 'contacts'
                    },
                },
                {
                    path: '/contacts/:id',
                    name: 'contacts-detail',
                    component: () => import('./views/organization/contacts/Contact.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Kontakte', url: '/contacts', active: true},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Kontakt bearbeiten',
                        parent: 'contacts'
                    }
                },
                {
                    path: '/suppliers',
                    name: 'suppliers',
                    component: () => import('./views/organization/suppliers/Suppliers.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Lieferanten', url: '/suppliers', active: true}
                        ],
                        pageTitle: 'Lieferanten',
                        parent: 'suppliers'
                    },
                },
                {
                    path: '/suppliers/add',
                    name: 'suppliers-add',
                    component: () => import('./views/organization/suppliers/AddSupplier.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Lieferanten', url: '/suppliers', active: true},
                            {title: 'Hinzufügen', active: true}
                        ],
                        pageTitle: 'Lieferant hinzufügen',
                        parent: 'suppliers'
                    },
                },

                {
                    path: '/suppliers/:id',
                    name: 'suppliers-detail',
                    component: () => import('./views/organization/suppliers/Supplier.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Organization', url: '/customers'},
                            {title: 'Lieferanten', url: '/suppliers', active: true},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Lieferant bearbeiten',
                        parent: 'suppliers'
                    }
                },
                // =============================================================================
                // Distribution Routes
                // =============================================================================
                {
                    path: '/offers',
                    name: 'offers',
                    component: () => import('./views/distribution/offers/Offers.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Angebote', url: '/offers', active: true}
                        ],
                        pageTitle: 'Angebote'
                    },
                },
                {
                    path: '/offers/add',
                    name: 'offers-add',
                    component: () => import('./views/distribution/offers/AddOffer.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Angebote', url: '/offers', active: false},
                            {title: 'Erstellen', active: true}
                        ],
                        pageTitle: 'Angebot erstellen',
                        parent: 'offers'
                    },
                },
                {
                    path: '/sprints',
                    name: 'sprint-list',
                    component: () => import('./views/project/sprint/SprintList.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'projects'
                    },
                },
                {
                    path: '/offers/:id',
                    name: 'offers-details',
                    component: () => import('./views/distribution/offers/OfferDetails.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'offers'
                    },
                    children: [
                        {
                            path: '/offers/:id/edit',
                            name: 'offers-detail',
                            component: () => import('./views/distribution/offers/Offer.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        },
                        {
                            path: '/offers/:id/preview/',
                            name: 'offers-view',
                            component: () => import('./views/distribution/offers/ViewOffer.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            }
                        },
                        {
                            path: '/offers/:id/task-allocation',
                            name: 'offers-task-allocation',
                            component: () => import('./views/distribution/offers/TaskAllocation.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        },
                        {
                            path: '/offers/:id/sprint-planer',
                            name: 'offer-sprint-planer',
                            component: () => import('./views/distribution/offers/SprintPlaner.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        },
                        {
                            path: '/offers/:id/sprint-planer/:sprintId',
                            name: 'offer-sprint-planer-detail',
                            component: () => import('./views/distribution/offers/SprintPlaner.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        },
                        {
                            path: '/offers/:id/planned-sales',
                            name: 'offers-planned-sales',
                            component: () => import('./views/distribution/offers/accounting/OrderPlannedSales'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        },
                        {
                            path: '/offers/:id/accounting',
                            name: 'offers-accounting',
                            component: () => import('./views/distribution/offers/OfferAccounting.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                            children: [
                                {
                                    path: '/offers/:id/accounting/already-billed',
                                    name: 'offers-accounting-already-billed',
                                    component: () => import('./views/distribution/offers/accounting/AccountingAlreadyBilled.vue'),
                                    meta: {
                                        authRequired: true,
                                        parent: 'offers-accounting'
                                    },
                                },
                                {
                                    path: '/offers/:id/accounting/positions',
                                    name: 'offers-accounting-positions',
                                    component: () => import('./views/distribution/offers/accounting/AccountingPositions.vue'),
                                    meta: {
                                        authRequired: true,
                                        parent: 'offers-accounting'
                                    },
                                },
                                {
                                    path: '/offers/:id/accounting/create-invoice',
                                    name: 'offers-accounting-create-invoice',
                                    component: () => import('./views/distribution/offers/accounting/AccountingCreateInvoice.vue'),
                                    meta: {
                                        authRequired: true,
                                        parent: 'offers-accounting'
                                    },
                                },
                                {
                                    path: '/offers/:id/accounting/create-final-invoice',
                                    name: 'offers-accounting-create-final-invoice',
                                    component: () => import('./views/distribution/offers/accounting/AccountingCreateFinalInvoice.vue'),
                                    meta: {
                                        authRequired: true,
                                        parent: 'offers-accounting'
                                    },
                                    children: [
                                        {
                                            path: '/offers/:id/accounting/create-final-invoice/fixed-price',
                                            name: 'offers-accounting-create-final-invoice-fixed-price',
                                            component: () => import('./views/distribution/offers/accounting/finalInvoice/fixedPrice.vue'),
                                            meta: {
                                                authRequired: true,
                                                parent: 'offers-accounting-create-final-invoice'
                                            }
                                        },
                                        {
                                            path: '/offers/:id/accounting/create-final-invoice/offer-items',
                                            name: 'offers-accounting-create-final-invoice-offer-items',
                                            component: () => import('./views/distribution/offers/accounting/finalInvoice/offerItems.vue'),
                                            meta: {
                                                authRequired: true,
                                                parent: 'offers-accounting-create-final-invoice'
                                            }
                                        }
                                    ]
                                },
                                {
                                    path: '/offers/:id/accounting/assignment',
                                    name: 'offers-accounting-assignment',
                                    component: () => import('./views/distribution/offers/accounting/AccountingAssignment.vue'),
                                    meta: {
                                        authRequired: true,
                                        parent: 'offers-accounting'
                                    },
                                    children: [
                                        {
                                            path: '/offers/:id/accounting/assignment/positions',
                                            name: 'offers-accounting-assignment-positions',
                                            component: () => import('./views/distribution/offers/accounting/assignment/AssignmentAccountingPositions.vue'),
                                            meta: {
                                                authRequired: true,
                                                parent: 'offers-accounting-assignment'
                                            },
                                        },
                                        {
                                            path: '/offers/:id/accounting/assignment/invoice-items',
                                            name: 'offers-accounting-assignment-invoice-items',
                                            component: () => import('./views/distribution/offers/accounting/assignment/AssignmentInvoiceItems'),
                                            meta: {
                                                authRequired: true,
                                                parent: 'offers-accounting-assignment'
                                            },
                                        }
                                    ]
                                },
                                {
                                    path: '/offers/:id/billing-profil',
                                    name: 'offers-billing-profil',
                                    component: () => import('./views/distribution/offers/accounting/OfferBillingProfile.vue'),
                                    meta: {
                                        authRequired: true,
                                        parent: 'offers-accounting'
                                    },
                                },
                            ]
                        },
                        {
                            path: '/offers/:id/tasks',
                            name: 'offers-tasks',
                            component: () => import('./views/distribution/offers/OfferTasks.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        },
                        {
                            path: '/offers/:id/processing-history',
                            name: 'offers-processing-history',
                            component: () => import('./views/distribution/offers/OfferProcessingHistory.vue'),
                            meta: {
                                authRequired: true,
                                parent: 'offers'
                            },
                        }
                    ]
                },


                {
                    path: '/offers/:id/task-allocation',
                    name: 'offers-task-allocation',
                    component: () => import('./views/distribution/offers/TaskAllocation.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'offers'
                    },
                },
                {
                    path: '/offers/duplicate/:id',
                    name: 'offers-duplicate',
                    component: () => import('./views/distribution/offers/AddOffer.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Angebote', url: '/offers', active: false},
                            {title: 'Duplizieren', active: true}
                        ],
                        pageTitle: 'Angebot duplizieren',
                        parent: 'offers'
                    },
                },
                {
                    path: '/order-confirmation/create/:id',
                    name: 'order-confirmation-create',
                    component: () => import('./views/distribution/offers/AddOffer.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Angebote', url: '/offers', active: false},
                            {title: 'Erstellen', active: true}
                        ],
                        pageTitle: 'Auftragsbestätigung erstellen',
                        parent: 'offers'
                    },
                },
                {
                    path: '/order-confirmation/view/:id',
                    name: 'order-confirmation-view',
                    component: () => import('./views/distribution/offers/ViewOffer.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Angebote', url: '/offers', active: false},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Auftragsbestätigung bearbeiten',
                        parent: 'offers'
                    },
                },
                {
                    path: '/invoices',
                    name: 'invoices',
                    component: () => import('./views/distribution/orders/Orders.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Belege', url: '/invoices', active: true}
                        ],
                        pageTitle: 'Belege'
                    },
                },
                {
                    path: '/invoices/add',
                    name: 'invoices-add',
                    component: () => import('./views/distribution/orders/AddOrder.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Belege', url: '/invoices', active: true},
                            {title: 'Erstellen', active: true}
                        ],
                        pageTitle: 'Belege erstellen',
                        parent: 'invoices'
                    },
                },
                {
                    path: '/invoices/duplicate/:id',
                    name: 'invoices-duplicate',
                    component: () => import('./views/distribution/orders/AddOrder.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Belege', url: '/invoices', active: true},
                            {title: 'Duplizieren', active: true}
                        ],
                        pageTitle: 'Belege duplizieren',
                        parent: 'invoices'
                    },
                },
                {
                    path: '/invoices/view/:id',
                    name: 'invoices-view',
                    component: () => import('./views/distribution/orders/ViewOrder.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Belege', url: '/invoices', active: true},
                            {title: 'Details', active: true}
                        ],
                        pageTitle: 'Beleg details',
                        parent: 'invoices'
                    },
                },
                {
                    path: '/invoices/:id',
                    name: 'invoices-detail',
                    component: () => import('./views/distribution/orders/Order.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/offers'},
                            {title: 'Belege', url: '/invoices', active: true},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Beleg bearbeiten',
                        parent: 'invoices'
                    },
                },
                {
                    path: '/contracts',
                    name: 'contracts',
                    component: () => import('./views/distribution/contracts/Contracts.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Verträge', url: '/contracts', active: true}
                        ],
                        pageTitle: 'Verträge'
                    },
                },
                {
                    path: '/contracts/add',
                    name: 'contracts-add',
                    component: () => import('./views/distribution/contracts/AddContracts.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Verträge', url: '/contracts'},
                            {title: 'Vertrag hinzufügen', active: true}
                        ],
                        pageTitle: 'Vertrag hinzufügen',
                        parent: 'contracts'
                    }
                },
                {
                    path: '/contracts/:id',
                    name: 'contracts-detail',
                    component: () => import('./views/distribution/contracts/Contract.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Verträge', url: '/contracts'},
                            {title: 'Vertrag bearbeiten', active: true}
                        ],
                        pageTitle: 'Vertrag bearbeiten',
                        parent: 'contracts'
                    }
                },
                // =============================================================================
                // B2Bsellers Specific Routes
                // =============================================================================
                {
                    path: '/b2b-sellers/demoshop',
                    name: 'b2b-sellers-demoshop-list',
                    component: () => import('./views/b2bsellers-specific/demoshop/Demoshop-list'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'B2Bsellers Demoshops', url: '/b2b-sellers/demoshop', active: true}
                        ],
                        pageTitle: 'B2Bsellers Demoshopverwaltung',
                        parent: 'b2b-sellers-demoshop'
                    },
                },
                {
                    path: '/b2b-sellers/demoshop/add',
                    name: 'b2b-sellers-demoshop-add',
                    component: () => import('./views/b2bsellers-specific/demoshop/Demoshop-detail'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'B2Bsellers Demoshops', url: '/b2b-sellers/demoshop', active: true}
                        ],
                        pageTitle: 'Demoshop hinzufügen',
                        parent: 'b2b-sellers-demoshop'
                    }
                },
                {
                    path: '/b2b-sellers/demoshop/:id',
                    name: 'b2b-sellers-demoshop-detail',
                    component: () => import('./views/b2bsellers-specific/demoshop/Demoshop-detail'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'B2Bsellers Demoshops', url: '/b2b-sellers/demoshop'},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Demoshop bearbeiten ',
                        parent: 'b2b-sellers-demoshop'
                    }
                },
                // =============================================================================
                // Monitoring Routes
                // =============================================================================
                {
                    path: '/ssl-certificates',
                    name: 'ssl-certificates',
                    component: () => import('./views/monitoring/ssl-certificates/SSL-Certificates'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Monitoring', url: '/ssl-certificates'},
                            {title: 'SSL-Zertifikate', url: '/ssl-certificates', active: true}
                        ],
                        pageTitle: 'SSL-Zertifikate'
                    },
                },
                {
                    path: '/domain-uptime-robot',
                    name: 'domain-uptime-robot',
                    component: () => import('./views/monitoring/domain-uptime-robot/domain-uptime-robot'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Monitoring', url: '/'},
                            {title: 'Uptime', url: '/', active: true}
                        ],
                        pageTitle: 'Domain Uptime Monitor'
                    },
                },
                {
                    path: '/products-and-services',
                    name: 'products-and-services',
                    component: () => import('./views/distribution/products/Products.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Produkte & Dienstleistungen', active: true}
                        ],
                        pageTitle: 'Produkte & Dienstleistungen'
                    }
                },
                {
                    path: '/products-and-services/add',
                    name: 'products-and-services-add',
                    component: () => import('./views/distribution/products/AddProduct.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Produkte & Dienstleistungen', url: '/products-and-services'},
                            {title: 'Hinzufügen', active: true}
                        ],
                        pageTitle: 'Produkt hinzufügen',
                        parent: 'products-and-services'
                    }
                },
                {
                    path: '/products-and-services/:id',
                    name: 'products-and-services-detail',
                    component: () => import('./views/distribution/products/Product.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Produkte & Dienstleistungen', url: '/products-and-services'},
                            {title: 'Bearbeiten', active: true}
                        ],
                        pageTitle: 'Produkt bearbeiten ',
                        parent: 'products-and-services'
                    }
                },
                // =============================================================================
                // Accounting Routes
                // =============================================================================
                {
                    path: '/accountings',
                    name: 'accountings',
                    component: () => import('./views/distribution/accountings/Accountings.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Abrechnungen', url: '/accountings', active: true}
                        ],
                        pageTitle: 'Abrechnungen'
                    },
                },
                {
                    path: '/accounting/add',
                    name: 'accounting-add',
                    component: () => import('./views/distribution/accountings/AddAccounting.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Abrechnungen', url: '/accountings'},
                            {title: 'Abrechnung hinzufügen', active: true}
                        ],
                        pageTitle: 'Abrechnung hinzufügen',
                        parent: 'accountings'
                    }
                },
                {
                    path: '/accounting/:id',
                    name: 'accounting-edit',
                    component: () => import('./views/distribution/accountings/Accounting.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/invoices'},
                            {title: 'Abrechnungen', url: '/accountings'},
                            {title: 'Abrechnung bearbeiten', active: true}
                        ],
                        pageTitle: 'Abrechnung bearbeiten',
                        parent: 'accountings'
                    }
                },
                // =============================================================================
                // Controlling
                // =============================================================================
                {
                    path: '/planned-sales',
                    name: 'plannedsales',
                    component: () => import('./views/distribution/plannedsales/PlannedSales.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Controlling', url: '/controlling'},
                            {title: 'Planumsätze', url: '/planned-sales', active: true}
                        ],
                        pageTitle: 'Planumsätze'
                    },
                },

                {
                    path: '/statistics', name: 'statistics', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // Human Ressources Routes
                // =============================================================================
                {
                    path: '/employees',
                    name: 'employees',
                    component: () => import('./views/human-resources/employees/Employees.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/employees'},
                            {title: 'Mitarbeiter', active: true},
                        ],
                        pageTitle: 'Mitarbeiter Liste'
                    },
                },
                {
                    path: '/employees/add',
                    name: 'employees-add',
                    component: () => import('./views/human-resources/employees/AddEmployees.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/employees'},
                            {title: 'Mitarbeiter', url: '/employees'},
                            {title: 'Mitarbeiter hinzufügen', active: true}
                        ],
                        pageTitle: 'Mitarbeiter hinzufügen'
                    }
                },
                {
                    path: '/employee/:id',
                    name: 'employee',
                    component: () => import('./views/human-resources/employees/Employee.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/employees'},
                            {title: 'Mitarbeiter', url: '/employees'},
                            {title: 'Mitarbeiter verwalten', active: true}
                        ],
                        pageTitle: 'Mitarbeiter verwalten'
                    }
                },
                {
                    path: '/teams', name: 'teams', component: () => import('./views/human-resources/teams/Teams.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/employees'},
                            {title: 'Teams', url: '/teams', active: true}
                        ],
                        pageTitle: 'Teams Liste'
                    },
                },
                {
                    path: '/teams/add',
                    name: 'teams-add',
                    component: () => import('./views/human-resources/teams/AddTeams.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/teams'},
                            {title: 'Teams', url: '/teams'},
                            {title: 'Team hinzufügen', active: true}
                        ],
                        pageTitle: 'Teams hinzufügen'
                    }
                },
                {
                    path: '/team/:id',
                    name: 'team',
                    component: () => import('./views/human-resources/teams/Team.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/teams'},
                            {title: 'Teams', url: '/teams'},
                            {title: 'Team verwalten', active: true}
                        ],
                        pageTitle: 'Teams verwalten'
                    }
                },
                {
                    path: '/manage-commissions',
                    name: 'manage-commissions',
                    component: () => import('./views/human-resources/commision/CommisionItems.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Human Resources', url: '/employees'},
                            {title: 'Provisionen', url: '/manage-commissions', active: true}
                        ],
                        pageTitle: 'Meine Provisionen'
                    },
                },
                {
                    path: '/manage-commissions/:userId/:year/:month',
                    name: 'manage-user-commissions',
                    component: () => import('./views/human-resources/commision/CommisionItems.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'manage-commissions'
                    },

                },
                {
                    path: '/manage-reimbursement',
                    name: 'manage-reimbursement',
                    component: () => import('./views/human-resources/reimbursement/Reimbursements.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'manage-commissions'
                    },
                },{
                    path: '/manage-lunchbreak-cash-desk',
                    name: 'manage-lunchbreak-cash-desk',
                    component: () => import('./views/human-resources/lunchbreak-cash-desk/LunchbreakCashDesk.vue'),
                    meta: {
                        authRequired: true,
                        parent: 'lunchbreak-cash-desk'
                    },
                },
                // =============================================================================
                // activity-protocol Routes
                // =============================================================================
                {
                    path: '/activity-protocol',
                    name: 'activity-protocol',
                    component: () => import('./views/activity-protocol/Activities.vue'),
                    meta: {authRequired: true},
                },

                // =============================================================================
                // b2bsellers Licensemanager Routes
                // =============================================================================
                {
                    path: '/licence-manager',
                    name: 'licence-manager',
                    component: () => import('./views/b2bsellers-specific/licence-manager/Licences.vue'),
                    meta: {
                        authRequired: true,
                        pageTitle: 'Lizenzverwaltung'
                    },
                },

                {
                    path: '/licence-manager/add',
                    name: 'licence-manager-add',
                    component: () => import('./views/b2bsellers-specific/licence-manager/Licence-detail.vue'),
                    meta: {
                        authRequired: true,
                        pageTitle: 'Lizenz hinzufügen'
                    },
                },
                {
                    path: '/licence-manager/:id',
                    name: 'licence-manager-detail',
                    component: () => import('./views/b2bsellers-specific/licence-manager/Licence-detail.vue'),
                    meta: {
                        authRequired: true,
                        pageTitle: 'Lizenz bearbeiten'
                    },
                },
                // =============================================================================
                // documents Routes
                // =============================================================================
                {
                    path: '/documents', name: 'documents', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // Marketing Routes
                // =============================================================================
                {
                    path: '/mailings', name: 'mailings', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/campaigns', name: 'campaigns', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // Tax Accounting Routes
                // =============================================================================
                {
                    path: '/bank-transactions',
                    name: 'bank-transactions',
                    component: () => import('./views/accounting/bank-transactions.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Bank Transaktionen', url: '/bank-transactions', active: true}
                        ],
                        pageTitle: 'Bank Transaktionen'
                    },
                },{
                    path: '/bank-transactions/:year/:month',
                    name: 'bank-transactions-month-year',
                    component: () => import('./views/accounting/bank-transactions.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Bank Transaktionen', url: '/bank-transactions', active: true}
                        ],
                        pageTitle: 'Bank Transaktionen'
                    },
                },
                {
                    path: '/document-management',
                    name: 'documents',
                    component: () => import('./views/accounting/documents.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Bank Transaktionen', url: '/document-management', active: true}
                        ],
                        pageTitle: 'Buchhaltung Dokumente'
                    },
                },

                {
                    path: '/document-management/:fileId',
                    name: 'document-file-edit',
                    component: () => import('./views/accounting/documents.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Bank Transaktionen', url: '/document-management', active: true}
                        ],
                        parent: 'documents'
                    },

                },
                {
                    path: '/billing-run',
                    name: 'billingrun',
                    component: () => import('./views/accounting/billing-run-list.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Bank Transaktionen', url: '/document-management', active: true}
                        ],
                        pageTitle: 'Nächster Rechnungslauf'
                    },
                },
                {
                    path: '/bills-mailbox',
                    name: 'billingmails',
                    component: () => import('./views/accounting/billing-mails.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Postfach Rechnungen', url: '/bills-mailbox', active: true}
                        ],
                        pageTitle: 'Postfach Rechnung'
                    },
                },

                {
                    path: '/controlling/contract',
                    name: 'contract-controlling',
                    component: () => import('./views/accounting/controlling/ContractControlling.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Controlling', url: '/controlling', active: true}
                        ],
                        pageTitle: 'Contract Controlling'
                    },
                },
                {
                    path: '/controlling',
                    name: 'controlling',
                    component: () => import('./views/accounting/controlling/Controlling.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Buchhaltung', url: '/bank-transactions'},
                            {title: 'Controlling', url: '/controlling', active: true}
                        ],
                        pageTitle: 'Controlling'
                    },
                },
                {
                    path: '/bonus-statistic',
                    name: 'bonus-statistic',
                    component: () => import('./views/accounting/controlling/BonusStatistic.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Controlling', url: '/controlling', active: true}
                        ],
                        pageTitle: 'Bonus-Statstik'
                    },
                },

                {
                    path: '/docs',
                    name: 'docs',
                    component: () => import('./views/docs/Docs.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Knowledge Base', url: '/docs', active: true}
                        ],
                        parent: 'docs'
                    },
                },
                {
                    path: '/docs/*',
                    name: 'docs',
                    component: () => import('./views/docs/Docs.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Knowledge Base', url: '/docs', active: true}
                        ],
                        parent: 'docs'
                    },
                },


                // =============================================================================
                // Administration Routes
                // =============================================================================
                {
                    path: '/permissions',
                    name: 'permissions',
                    component: () => import('./views/administration/permission/Permissions.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/email-addresses', name: 'email-addresses', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/working-time-settings',
                    name: 'working-time-settings',
                    component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/absence-types', name: 'absence-types', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/payment-methods', name: 'payment-methods', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/tags', name: 'tags', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/categories', name: 'categories', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/administrate-invoices',
                    name: 'administrate-invoices',
                    component: () => import('./views/administration/invoice/Invoice.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/bank-api', name: 'bank-api', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/email-templates',
                    name: 'email-templates',
                    component: () => import('./views/administration/email-templates/Templates.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/administrate-workflows',
                    name: 'administrate-workflows',
                    component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/data-import',
                    name: 'data-import',
                    component: () => import('./views/administration/data-import/DataImport.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Import Step1', active: true},
                        ],
                        pageTitle: 'Import'
                    },
                },
                {
                    path: '/data-import/step2',
                    name: 'data-import/step2',
                    component: () => import('./views/administration/data-import/DataImportStepTwo.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Import Step2', active: true},
                        ],
                        pageTitle: 'Import'
                    },
                },
                {
                    path: '/data-import/validate-bank-transactions/:fileId',
                    name: 'data-import/validate-bank-transactions',
                    component: () => import('./views/administration/data-import/ValidateBankTransactionCSV.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Validate Import Bank-Transactions', active: true},
                        ],
                        pageTitle: 'Validate Import CSV'
                    },
                },
                {
                    path: '/general-settings',
                    name: 'general-settings',
                    component: () => import('./views/administration/general-settings/GeneralSettings.vue'),
                    meta: {authRequired: true},
                },

                {
                    path: '/general-settings/:setting',
                    name: 'general-settings-setting',
                    component: () => import('./views/administration/general-settings/GeneralSettings.vue'),
                    meta: {
                        authRequired: true,
                        parent: "general-settings"
                    }
                },
                // =============================================================================
                // Support Routes
                // =============================================================================
                {
                    path: '/support', name: 'support', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // Project Management Routes
                // =============================================================================
                {
                    path: '/projects', name: 'projects', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/reports', name: 'reports', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                {
                    path: '/protocols', name: 'protocols', component: () => import('./views/Home.vue'),
                    meta: {authRequired: true},
                },
                // =============================================================================
                // User Routes
                // =============================================================================
                {
                    path: '/profile', name: 'profile', component: () => import('./views/user/Profile.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Account', url: '/profile'},
                            {title: 'Profil', url: '/profile', active: true}
                        ],
                        pageTitle: 'Profil'
                    },
                },
                // =============================================================================
                // Debugging Routes
                // =============================================================================
                {
                    path: '/debugging', name: 'debugging', component: () => import('./views/debugging/Debugging.vue'),
                    meta: {
                        authRequired: true,
                        breadcrumb: [
                            {title: 'Debugging', url: '/debugging', active: true},
                        ],
                        pageTitle: 'Debugging'
                    },
                },
            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('./layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/login',
                    name: 'page-login',
                    component: () => import('./views/pages/Login.vue'),
                    meta: {
                        guestOnly: true
                    },
                },
                {
                    path: '/register',
                    name: 'page-register',
                    component: () => import('./views/pages/Register.vue'),
                    meta: {
                        guestOnly: true
                    },
                },
                {
                    path: '/error-404',
                    name: 'page-error-404',
                    component: () => import('./views/pages/Error404.vue')
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        }
    ],
})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');

    if (appLoading) {
        appLoading.style.display = "none";
    }

    /*
    document.querySelectorAll('body > div.vs-component').forEach(el => {
        el.remove();
    });

    document.querySelectorAll('body > div.vs-content-sidebar').forEach(el => {
        el.remove();
    });
    */
});

router.beforeEach((to, from, next) => {

    if (to.meta.authRequired && localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace')) == null) {
        next({path: '/login'});
        return;
    } else if (to.meta.guestOnly && localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace')) !== null) {
        next({path: '/'});
        return;
    }

    next();
});

export default router
